

// const ADD_URL = URL_APP + "api/v1/rincian_obyek/";

  import Joi from "joi";
  const schema = Joi.object().keys({
    username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(3).max(13).required(),
    password: Joi.string().min(6).required(),
  });

  import { Notify  } from 'quasar'

  export default {
    name: "monev_login",
    data : function(){
      return {
          testtttttt : [],
          errorMessage: '',
          user : {
            username : "",
            password : ""
          },

          url : {
            LOGIN_URL : this.$store.state.url.URL_APP + "auth/login",
          }
      }
    },
    watch: {
      user: {
          handler() {
              this.errorMessage = "";
          },
          deep: true
      }
    },
    methods: {
      // go_register : function(){
      //   alert(App.data().sesseee);
      //   this.displayed = 'block';
      // },
      btn_login : function(){

        

        this.errorMessage = '';
        if(this.validUser()){
          this.$store.commit("shoWLoading");
          // this.$q.notify("hi");

          // this.$store.commit("shoWNotify", 'Kiken', 'primary', 'timer');
          const body = {
            username : this.user.username,
            password : this.user.password
          }
          fetch(this.url.LOGIN_URL, {
            method : 'POST',
            headers : {
              'content-type' : 'application/json',
            },
            body : JSON.stringify(body),
          }).then((response) => {
            
            // console.log(response);
            
                if (response.ok) {
                    return response.json();
                    this.$store.commit("shoWLoading");
                }

                return response.json().then(error => {
                    throw new Error(error.message);
                    this.$store.commit("shoWLoading");
                });
            })
            .then((result) => {
              console.log("=============================");
              // console.log(result);
              // menyimpan token yang tergenerate dari server
              localStorage.token = result.token;
              localStorage.profile = JSON.stringify(result.profile);
              setTimeout(() => {
                this.$store.commit("hideLoading")
                this.$router.push('/');
                // location.reload();
              }, 1000);
            })
            .catch(error => {
              setTimeout(() => {
                this.$store.commit("hideLoading")
                this.errorMessage = error.message;
              }, 1000);
            });;
        }



      },
      validUser: function(){
        const result = Joi.validate(this.user, schema);
        if (result.error === null) {
          return true;
        }
        if (result.error.message.includes("username")) {
          this.errorMessage = "Username tidak valid";
        } else {
          this.errorMessage = "Username tidak valid2";
        }
        return false;
      },



      testGetData : function(){
        // axios.get('https://api.coindesk.com/v1/bpi/currentprice.json')
        // .then(response => {
        //   console.log(response);
        //   this.testtttttt = response
        // })
        // .catch(error => {
        //   alert(error)
        //   console.log(error);
        // });




         axios
      .get('https://api.coindesk.com/v1/bpi/currentprice.json')
      .then(response => (this.testtttttt = response))






      }

    },
    mounted : function(){

      this.testGetData()
      

    },
  };
